const autoNgTemplateLoaderTemplate1 = require('./por-switch-field.html');

import angular from 'angular';

import './por-switch-field.css';

export const porSwitchField = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    tooltip: '@',
    ngModel: '=',
    label: '@',
    name: '@',
    labelClass: '@',
    disabled: '<',
    onChange: '<',
  },
};

angular.module('portainer.app').component('porSwitchField', porSwitchField);
