const autoNgTemplateLoaderTemplate1 = require('./git-form.html');

import controller from './git-form.controller.js';

export const gitForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    model: '<',
    onChange: '<',
  },
};
