export function KubernetesNamespace() {
  return {
    Id: '',
    Name: '',
    CreationDate: '',
    Status: '',
    Yaml: '',
    ResourcePoolName: '',
    ResourcePoolOwner: '',
  };
}
