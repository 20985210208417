const autoNgTemplateLoaderTemplate1 = require('./git-form-auth-fieldset.html');

import controller from './git-form-auth-fieldset.controller.js';

export const gitFormAuthFieldset = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    model: '<',
    onChange: '<',
  },
};
